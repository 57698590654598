// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topSideBar {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(28, 28, 28, 0.1019607843);
  box-shadow: 0 5px 10px rgba(28, 28, 28, 0.1019607843);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topSideBar .pageRoute {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.topSideBar .pageRoute .switchSideBarIcon {
  width: 40px;
  height: 30px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.topSideBar .pageRoute .switchSideBarIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.topSideBar .pageRoute h3 {
  font-size: 18px;
  color: rgba(28, 28, 28, 0.4);
  font-family: gilroy-regular;
}
.topSideBar .pageRoute h3 .slash {
  margin: 0 5px;
}
.topSideBar .pageRoute h3 .pageTitleinRoutes {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/TopSideBar/TopSideBar.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uDAAA;EACA,qDAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACZ;AAAY;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAEhB;AAEQ;EACI,eAAA;EACA,4BAAA;EACA,2BAAA;AAAZ;AAEY;EACI,aAAA;AAAhB;AAGY;EACI,WAAA;AADhB","sourcesContent":[".topSideBar {\n    width: 100%;\n    padding: 20px;\n    border-bottom: 1px solid #1C1C1C1A;\n    box-shadow: 0 5px 10px #1C1C1C1A;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    .pageRoute {\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n\n        .switchSideBarIcon {\n            width: 40px;\n            height: 30px;\n            margin-right: 20px;\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            cursor: pointer;\n            img{\n                width: 100%;\n                height: 100%;\n                object-fit: cover;\n            }\n        }\n\n        h3 {\n            font-size: 18px;\n            color: #1C1C1C66;\n            font-family: gilroy-regular;\n\n            .slash {\n                margin: 0 5px;\n            }\n\n            .pageTitleinRoutes {\n                color: #000;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
